.App-header {
    height: 100px;
    border-bottom: 1px solid #ccc;
    padding: 15px;
}

.App-header img {
    max-height: 100px;
}

* {
    color: #52545d;
}

h1, h2, h3, h4, h5 {
    margin: 0;
    color: #41455a;
    text-transform: uppercase;
}

.container {
    display: flex;
    height: calc(100vh - 131px)
}

.sidebar {
    background: #dcdcdc;
    width: 250px;
    align-self: stretch;
    padding-top: 30px;
}

.sidebar ul li {
    list-style: none;
}

.sidebar .heading {
    font-size: 14px;
    margin-top: 30px;
    margin-bottom: 15px;
}

.sidebar a {
    text-decoration: none;
    color: #52545d;
    display: block;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 15px;
}

.sidebar a:hover {
    background: rgba(0,0,0,0.15);
    font-weight: bold;
}

.sidebar h5 {
    text-transform: uppercase;
    color: #41455a;
    margin: 0;
}

.sidebar .is-active {
    background: rgba(0,0,0,0.5);
    color: #Fff;
    font-weight: bold;
}

.main-content {
    box-shadow: inset 0px 4px 6px rgba(50,50,50,0.25);
    justify-content: stretch;
    align-items: stretch;
    height: calc(100vh - 171px);
    width: calc(100vw - 200px);
    padding: 20px;
    position: relative;
}

.main-content.login {
    width: 100vw;
    height: calc(100vh - 120px);
}

.main-content.login .page {
    overflow: auto !important;
}

.login-container {
    display: flex;
    flex-direction: column;
    max-width: 400px;
}

.login-container * {
    margin: 7px 0;
    padding: 10px 15px !important;
}

.page {
    border: 1px solid #ccc;
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 75px;
    padding: 30px;
    overflow-y: scroll;
}

.controls {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
}

.controls button {
    margin-right: 20px;
}

.page-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.page-header h1 {
    margin-right: auto;
}

.page-header .search,
.select-field select {
    padding: 12px;
}

.select-field select {
    max-width: 200px;
    margin: 0 12px 0 0;
}

.content {
    margin-top: 30px;
    padding: 20px;
}

.tabular-data {
    display: flex;
    flex-direction: column;
    background: rgb(248, 248, 248);
}

.tabular-data .row {
    display: flex;
}

.tabular-data .row-heading {
    border: 1px solid #333;
}

.tabular-data .row .col {
    padding: 1px 10px;
    border: 1px solid #ccc;
    border-collapse: collapse;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.tabular-data .row .col.small {
    width: 2vw;
}

.tabular-data .row .col.medium {
    width: 5vw;
}

.tabular-data .row:nth-child(even) {
    background: #fff;
}

.tabular-data .row-heading .col {
    border: 0px;
    justify-content: center;
}

.tabular-data .row-heading .col:first-child {
    justify-content: flex-start;
}

.tabular-data .row-heading .col:last-child {
    justify-content: flex-end;
}

.tabular-data .row .col * {
    word-break: break-word;
}

.tabular-data.three-col .col {
    width: 33%;
}

.tabular-data.two-col .col {
    width: 50%;
}

.tabular-data .col input {
    margin-right: 15px;
}

.tabular-data .col .edit {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.tabular-data .col .edit:hover {
    opacity: 0.6;
}

.tabular-data.tabular-column {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid #ccc;
}

.tabular-data.tabular-column .col {
    padding: 15px;
    border: 1px solid #eee;
    margin: 5px 0;
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tabular-data.tabular-column .col > h4 {
    width: 200px;
    min-width: 200px;
}

.tabular-data.tabular-column .col > .editable-field {
    margin-left: 30px;
}

.tabular-data.tabular-column .col:not(.no-edit) > .editable-field > span.value-label {
    cursor: pointer;
    border-bottom: 1px solid #41455a;
}

.tabular-data.tabular-column .col.no-edit {
    background: rgb(243, 243, 243);
}

.tabular-data.tabular-column .col > .editable-field input,
.tabular-data.tabular-column .col > .editable-field select {
    padding: 3px 6px;
}

.tabular-data.tabular-column .col > .editable-field select {
    max-width: 220px;
}

.notification {
    padding: 10px;
    margin: 15px;
}

.notification.green {
    border: rgb(83, 172, 83);
    background: rgb(177, 201, 177);
    color: rgb(25, 66, 25);
}

.notification.red {
    border: rgb(172, 89, 83);
    background: rgb(201, 177, 177);
    color: rgb(66, 25, 25);
}

.content-control {
    margin-bottom: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.button {
    padding: 7px 20px;
    background: #333;
    color: #fff;
    border: 1px solid #000;
    box-shadow: 0px 3px 5px rgba(0,0,0,0.25);
    cursor: pointer;
    transition: all 0.15s ease-in;
    font-weight: bold;
}

.button:hover {
    background: #666;
}

.button.primary {
    background: #344498;
}

.button.primary:hover {
    background: #4961db;
}

.button.error {
    background: rgb(110, 25, 25);
}

.button.error:hover {
    background:rgb(167, 49, 49)
}

.filter-by-date-wrap {
    display: flex;
    padding: 5px 0px;
}

.filter-by-date-wrap > label {
    align-self: center;
    font-weight: bold;
    padding-right: 15px;
}

.filter-by-date {
    display: flex;
}

.filter-by-date > div {
    padding: 0px 10px;
}

.filter-by-date label {
    padding-right: 5px;
}

.filter-by-date input {
    padding: 5px;
}